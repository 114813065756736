@import './assets/variables.scss';

body {
  background-color: $BACKGROUND_COLOR !important;
}

body,
html {
  padding: 0;
  margin: 0;
}

.text-bg-title {
  background-color: $PRIMARY_COLOR !important;
  color: white !important;
  padding: 10px;
}

.text-justify {
  text-align: justify;
}
