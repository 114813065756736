@import './../../assets/variables.scss';

.bg-header-secondary {
  // background-attachment: fixed;
  // background-color: $HEADER_COLOR !important;
  // background-image: url('~/public/images/Header.png');
  // background-position: center center;
  // background-repeat: no-repeat;
  // background-size: cover;
  // height: $HEADER_HEIGHT;

  & img {
    height: $HEADER_HEIGHT;
  }
}

.justify-content {
  align-content: center;
  align-items: center;
  justify-content: center;
  justify-items: center;
}
